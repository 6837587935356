import Splide from "@splidejs/splide";

document.addEventListener("DOMContentLoaded", () => {
  // HOMEPAGE
  if (document.querySelector(".homepage")) {
    // Homepage header slider
    new Splide(".splide", {
      type: "loop",
      autoplay: true,
      interval: 5000,
      gap: 25,
    }).mount();
  }
});
